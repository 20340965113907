<template>
    <section class="apr-alert" :class="[`apr-alert--${status}`]">
        <i data-icon="true" class="apr-alert__icon" :class="iconName"></i>
        <span class="apr-alert__message">{{ message }}</span>
    </section>
</template>

<script setup lang="ts">
interface Props {
    blok?: any;
    message?: any;
    title?: string;
    icon?: string;
    status?: 'error' | 'success' | 'warning';
}
const props = defineProps<Props>();

const iconName = computed(() => (props.status === 'error' ? 'apr-icon-error' : 'apr-icon-alert'));
</script>

<style lang="postcss" scoped>
.apr-alert {
    @apply p-4 rounded-lg flex items-center border border-solid;

    &--error {
        @apply border-status-error-red-100 bg-status-error-red-8 text-status-error-red-100;
    }

    &--success {
        @apply border-link text-link;
        background: #1776ce1a;
    }

    &__icon {
        @apply text-current mr-2 -mb-[3px] shrink-0;
    }
}
</style>
